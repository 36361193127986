import {applyAttributesMixin} from '@/models/vue-mc/helpers';
import Model from './vue-mc/Model';
import Collection from './vue-mc/Collection';

export class Client extends Model {
    /**
     * Default attributes that define the "empty" state.
     *
     * @return {object}
     */
    defaults() {
        return {
            id: null,
            user: {},
            group: {},
        };
    }

    /**
     * Options of this model.
     *
     * @return {object}
     */
    options() {
        return {
            endpoint: 'clients',
        };
    }

    /**
     * API endpoints of this model. Usually we just need to specify `options().endpoint`,
     * but Client has special API endpoint pattern.
     *
     * @return {object}
     */
    routes() {
        return {
            fetch: 'group-users/{id}',
            delete: 'group-users/{id}',
        };
    }
};

export const Clients = applyAttributesMixin(class Clients extends Collection {
    /**
     * Options of this collection.
     *
     * @return {object}
     */
    options() {
        return {
            model: Client,
            endpoint: 'students',
        };
    }
});
