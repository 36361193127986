<template>
    <div class="card-stack">
        <!-- Client Details Form -->
        <b-card>
            <h4 class="pb-4">
                {{ $t('clients.edit.nav') }}
            </h4>

            <b-row>
                <b-col>
                    <b-form-group
                        :label="$t('users.model.name.label')"
                        class="mb-0"
                    >
                        <b-input
                            v-model="client.user.name"
                            readonly
                        />
                    </b-form-group>
                </b-col>

                <b-col>
                    <b-form-group
                        :label="$t('users.model.email.label')"
                        class="mb-0"
                    >
                        <b-input
                            v-model="client.user.email"
                            type="email"
                            readonly
                        />
                    </b-form-group>
                </b-col>
            </b-row>
        </b-card>

        <!-- Remove Client Box -->
        <b-card
            bg-variant="transparent"
            body-class="d-flex align-items-center bg-striped py-4"
        >
            <div>
                <h6>
                    {{ $t('clients.actions.removeClient') }}
                </h6>

                <small>
                    {{ $t('clients.messages.removeWarning') }}
                </small>
            </div>

            <b-btn
                v-b-modal="'modal-remove-client'"
                class="ml-auto btn-min-width"
                variant="white"
            >
                {{ $t('common.actions.remove') }}
            </b-btn>

            <modal-confirm
                id="modal-remove-client"
                body-class="d-none"
                :title="$t('clients.messages.removeConfirmation', {clientName: client.user.name})"
            >
                <template #button-confirm>
                    <btn-resource
                        variant="danger"
                        class="btn-min-width"
                        :resource="client"
                        to-delete
                        @click="removeClient()"
                    >
                        {{ $t('common.actions.remove') }}
                    </btn-resource>
                </template>
            </modal-confirm>
        </b-card>
    </div>
</template>

<script>
import {Client} from '@/models/Client';
import {detainFeedback} from '@/library/helpers';
import ModalConfirm from '@/components/common/ModalConfirm';

export default {
    name: 'ClientEdit',
    components: {ModalConfirm},
    props: {
        client: {
            type: Client,
            required: true,
        },
    },
    methods: {
        async removeClient() {
            await this.client.delete();

            detainFeedback(() => {
                // Navigate back to members index page.
                this.$router.push(this.$me.getLocation('members.index'));
            });
        },
    },
};
</script>
