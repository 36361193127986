<template>
    <b-modal
        v-bind="$attrs"
        size="sm"
        centered
        hide-header-close
        title-tag="h2"
        footer-class="justify-content-center"
    >
        <slot name="default" />

        <template #modal-footer="{cancel}">
            <b-btn
                class="btn-min-width"
                variant="light"
                @click="cancel()"
            >
                {{ $t('buttons.cancel') }}
            </b-btn>

            <slot name="button-confirm">
                <b-btn
                    class="btn-min-width"
                    variant="primary"
                    @click="$emit('confirmed')"
                >
                    {{ $t('buttons.confirm') }}
                </b-btn>
            </slot>
        </template>
    </b-modal>
</template>

<script>
/**
 * A modal component to confirm user action.
 *
 * Exposes slots:
 * - default -> The content of .modal-body
 * - button-confirm -> The button that will trigger the action
 *
 * Emits events:
 * - confirmed -> When button confirm is clicked
 */
export default {
    name: 'ModalConfirm',
};
</script>
